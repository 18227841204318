/* eslint-disable cds/no-next-imports */
import { forwardRef, PropsWithChildren, ReactNode } from 'react';
import { CSSProps, styled, VariantProps } from '@neui/core';
import NextLink from 'next/link';
import { Box, VStack } from '@neui/layout';

import { Card, CardProps } from '@components/neui-components/atoms/Card';
import { useMakeLink } from '@components/Link';
import { makeLinkTargetProps } from '@utils/helpers/linkHelpers';

const CardBody = styled(VStack, {
  height: '100%',
  padding: '$space$component-4',
  gap: '$space$component-7',
  '@md': {
    padding: '$space$component-6',
    gap: '$space$layout-4',
  },
});

const CardTop = styled(Box, {});

const CardFooter = styled(Box, {});

export interface TeaserCardProps
  extends VariantProps<typeof CardBody>,
    PropsWithChildren<CSSProps>,
    Partial<CardProps> {
  picture?: ReactNode;
  footer?: ReactNode;
}

export const TeaserCard = forwardRef<HTMLDivElement, TeaserCardProps>(
  (props, forwardedRef) => {
    const {
      children,
      picture,
      footer,
      css,
      href: inboundHref,
      ...rest
    } = props;

    const makeLink = useMakeLink();
    const href = makeLink({ href: inboundHref });
    const linkTargetProps = makeLinkTargetProps(href);

    const cardContent = (
      <Card ref={forwardedRef} {...linkTargetProps} {...rest}>
        {picture && picture}
        <CardBody css={css} justifyContent={'space-between'}>
          <CardTop>{children}</CardTop>
          {footer && <CardFooter>{footer}</CardFooter>}
        </CardBody>
      </Card>
    );

    return href !== undefined ? (
      <NextLink href={href} passHref>
        {cardContent}
      </NextLink>
    ) : (
      <>{cardContent}</>
    );
  },
);

TeaserCard.displayName = 'TeaserCard';
