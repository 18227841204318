import {
  forwardRef,
  HTMLAttributes,
  PropsWithChildren,
  ReactNode,
} from 'react';
import { CSSProps, styled, VariantProps, AsProps } from '@neui/core';
import { VStack } from '@neui/layout';
import { oceanTheme, sandTheme } from '@neui/styleguide-commerzbank';

import { LinkProps } from '@components/Link';

const CardWrapper = styled(VStack, {
  position: 'relative',
  borderRadius: '16px',
  backgroundColor: '$colors$petrol0',
  boxShadow: '0 2px 4px -1px rgba(0, 37, 48, 0.24)',
  height: '100%',
  overflow: 'hidden',
  zIndex: 0,
  cursor: 'pointer',
  transitionProperty: 'transform, box-shadow',
  transitionDuration: '240ms',
  transitionTimingFunction: 'cubic-bezier(0.1,0,0,1)',
  variants: {
    hover: {
      true: {
        '&:hover': {
          boxShadow: '0 16px 24px -8px rgba(0, 37, 48, 0.20)',
          transform: 'translateY(-8px)',
        },
        '&:active': {
          boxShadow: '0 24px 48px -12px rgba(0, 37, 48, 0.16)',
          transform: 'translateY(-12px)',
        },
      },
    },
    bordered: {
      true: {
        border: '1px solid $neutral400',
      },
    },
  },
  [`.${oceanTheme} &`]: {
    backgroundColor: '$colors$petrol700',
    boxShadow: '0 2px 4px -1px rgb(0 0 0 / 72%)',
  },
  [`.${sandTheme} &`]: {
    backgroundColor: 'white',
  },
});

const Wrapper = styled('a', {
  '&:focus-visible': {
    outline: 'none',
    [`${CardWrapper}`]: {
      outline: '2px solid $text-standard',
      outlineOffset: 4,
    },
  },
});

export interface CardProps
  extends VariantProps<typeof CardWrapper>,
    PropsWithChildren<CSSProps>,
    AsProps,
    CSSProps,
    HTMLAttributes<HTMLDivElement>,
    Pick<LinkProps, 'target' | 'rel'> {
  picture?: ReactNode;
  footer?: ReactNode;
  href?: string;
  tabIndex?: number;
}

export const Card = forwardRef<HTMLDivElement, CardProps>(
  (
    {
      href,
      onClick,
      picture,
      footer,
      children,
      css,
      tabIndex,
      target,
      rel,
      ...rest
    },
    forwardedRef,
  ) => {
    return (
      <Wrapper
        href={href}
        target={target}
        rel={rel}
        as={href ? 'a' : 'div'}
        onClick={onClick}
      >
        <CardWrapper
          {...rest}
          ref={forwardedRef}
          tabIndex={tabIndex}
          flexDirection={'column'}
          css={css}
        >
          {children}
        </CardWrapper>
      </Wrapper>
    );
  },
);
Card.displayName = 'Card';
