import { AsProps, CSSProps, styled } from '@neui/core';
import { Item } from '@radix-ui/react-accordion';
import { forwardRef } from 'react';

import type { AccordionItemProps as RadixItemProps } from '@radix-ui/react-accordion';

export type AccordionItemProps = AsProps & CSSProps & RadixItemProps & {};

const StyledItem = styled(Item, {
  width: '100%',
  '&:not(:last-child)': {
    boxShadow: '0px -1px 0px 0px #CED8DA inset;',
  },
});

export const AccordionItem = forwardRef<HTMLDivElement, AccordionItemProps>(
  ({ ...rest }, forwardedRef) => {
    return <StyledItem ref={forwardedRef} {...rest} />;
  },
);
AccordionItem.displayName = 'AccordionItem';
