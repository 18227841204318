import { AsProps, CSSProps, keyframes, styled } from '@neui/core';
import { Content } from '@radix-ui/react-accordion';
import { forwardRef } from 'react';
import { Typography } from '@neui/styleguide-commerzbank';

import type { CollapsibleContentProps as RadixContentProps } from '@radix-ui/react-accordion';

export type AccordionContentProps = AsProps & CSSProps & RadixContentProps & {};

const slideDown = keyframes({
  from: { height: 0 },
  to: { height: 'var(--radix-accordion-content-height)' },
});

const slideUp = keyframes({
  from: { height: 'var(--radix-accordion-content-height)' },
  to: { height: 0 },
});

const StyledContent = styled(Content, {
  paddingLeft: '$component-4',
  paddingRight: '$component-4',
  paddingBottom: '$component-6',
  overflow: 'hidden',

  '&[data-state="open"]': {
    animation: `${slideDown} 150ms ease-out`,
  },
  '&[data-state="closed"]': {
    animation: `${slideUp} 150ms ease-out`,
  },
});

const HiddenSeoContent = styled('div', {
  display: 'none',
});

export const AccordionContent = forwardRef<
  HTMLDivElement,
  AccordionContentProps
>(({ children, ...rest }, forwardedRef) => {
  return (
    <>
      <HiddenSeoContent>
        <Typography>{children}</Typography>
      </HiddenSeoContent>
      <StyledContent ref={forwardedRef} {...rest}>
        {children}
      </StyledContent>
    </>
  );
});
AccordionContent.displayName = 'AccordionContent';
