import { AsProps, CSSProps, styled } from '@neui/core';
import { ElementType, forwardRef, PropsWithChildren } from 'react';
import { Root } from '@radix-ui/react-accordion';
import { slugify } from '@cds/utils';

import { AccordionItem, AccordionItemProps } from '../atoms/AccordionItem';
import { AccordionTrigger } from '../atoms/AccordionTrigger';
import { AccordionContent } from '../atoms/AccordionContent';

import type {
  AccordionSingleProps,
  AccordionMultipleProps,
} from '@radix-ui/react-accordion';

export type AccordionComponentItemProps = Omit<AccordionItemProps, 'value'> &
  PropsWithChildren<{
    label: string;
    value?: string;
    titleAs?: ElementType;
  }>;

export type AccordionProps<T> = AsProps &
  CSSProps &
  T & {
    items: AccordionComponentItemProps[];
  };

const StyledRoot = styled(Root, {
  all: 'unset',
  width: '100%',
  overflow: 'visible',
});

export const Accordion = forwardRef<
  HTMLDivElement,
  AccordionProps<AccordionSingleProps | AccordionMultipleProps>
>(({ items, ...rest }, forwardedRef) => {
  return (
    <StyledRoot ref={forwardedRef} {...rest}>
      {items.map(({ children, value, label, titleAs, ...rest }, idx) => (
        <AccordionItem
          key={`${value || slugify(label)}-${idx}`}
          value={value || slugify(label)}
          {...rest}
        >
          <AccordionTrigger renderAs={titleAs}>{label}</AccordionTrigger>
          <AccordionContent>{children}</AccordionContent>
        </AccordionItem>
      ))}
    </StyledRoot>
  );
});
Accordion.displayName = 'Accordion';
