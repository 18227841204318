import { AsProps, CSSProps, styled } from '@neui/core';
import { Trigger } from '@radix-ui/react-accordion';
import { forwardRef } from 'react';
import {
  interaction_arrows_chevronDown,
  Typography,
} from '@neui/styleguide-commerzbank';
import { Box } from '@neui/layout';

import { Icon } from './Icon';

import type { CollapsibleTriggerProps as RadixTriggerProps } from '@radix-ui/react-accordion';

export type AccordionTriggerProps = AsProps & CSSProps & RadixTriggerProps & {};

const Overlay = styled(Box, {
  transition: 'background-color, transform 300ms ease-in',
  content: "''",
  position: 'absolute',
  backgroundColor: '#00',
  zIndex: 0,
  borderRadius: '$space$component-4',
  height: '100%',
  top: 0,
  bottom: 0,
  left: 'calc($space$component-4 * -1)',
  right: 'calc($space$component-4 * -1)',
});

const StyledTypography = styled(Typography, {
  position: 'relative',
  zIndex: 1,
  transition: 'all 100ms ease-out',
  transform: 'translateX(0px)',
  paddingRight: 48,
});

const IconWrapper = styled(Box, {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  height: '100%',
  width: 24,
  right: 0,
  top: 0,
  bottom: 0,
});

const StyledIcon = styled(Icon, {
  zIndex: 1,
  transition: 'all 100ms ease-out',
  transform: 'rotate(0deg)',
});

const StyledTrigger = styled(Trigger, {
  all: 'unset',
  position: 'relative',
  cursor: 'pointer',
  display: 'flex',
  paddingY: '$component-4',
  paddingX: 0,
  width: '100%',
  '@md': {
    width: '100%',
    paddingY: '$component-6',
    paddingX: 0,
  },
  '&:focus-visible': {
    [`& ${Overlay}`]: {
      outline: '2px solid $text-standard',
    },
  },
  '&:hover': {
    [`& ${Overlay}`]: {
      backgroundColor: '$background-hover',
    },
    [`& ${StyledTypography}`]: {
      transform: 'translateX($space$component-4)',
    },
  },
  '&[data-state="open"]': {
    [`& ${StyledIcon}`]: {
      transform: 'rotate(-180deg)',
    },
    [`& ${StyledTypography}`]: {
      transform: 'translateX($space$component-4)',
    },
  },
});

export const AccordionTrigger = forwardRef<
  HTMLButtonElement,
  AccordionTriggerProps
>(({ children, renderAs = 'span', ...rest }, forwardedRef) => {
  return (
    <StyledTrigger ref={forwardedRef} {...rest}>
      <IconWrapper>
        <StyledIcon size="small" icon={interaction_arrows_chevronDown} />
      </IconWrapper>
      <Overlay />
      <StyledTypography size={4} weight="medium" renderAs={renderAs}>
        {children}
      </StyledTypography>
    </StyledTrigger>
  );
});
AccordionTrigger.displayName = 'AccordionTrigger';
